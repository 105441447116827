@for (navigationItem of navigationItems; track $index) {
    <div class="mobi-ui-padding-t-lg">
        <a
            class="list-item-level-one mobi-ui-padding-xs"
            (click)="navigationClick.emit(navigationItem)"
            mobiTealiumTrackLinkOnsite="{{ navigationItem.title }}"
            [trackingLinkTarget]="navigationItem.urlLink"
            trackingLinkAction="click"
            trackingLinkCategory="OnSiteLink - Content Navigation"
        >
            <span
                class="mobi-ui-margin-l-xxs mobi-ui-ink-default mobi-ui-text-inked mobi-ui-font-size-150 xs:mobi-ui-font-size-300 mobi-ui-font-tisa"
            >
                <div class="order-number-title-container">
                    @if (navigationItem.orderNumber) {
                        <span class="order-number mobi-ui-margin-r-xxxs">{{ navigationItem.orderNumber }}</span>
                    }
                    <span class="title">{{ navigationItem.title }}</span>
                </div>
            </span>
            <mobi-ui-icon-major [isInked]="true" name="right"></mobi-ui-icon-major>
        </a>
        <ul class="mobi-ui-list-silent list-item-level-two mobi-ui-margin-l-lg">
            @if (navigationItem.subItems.length > 0) {
                @for (subItem of navigationItem.subItems; track $index) {
                    <li
                        (click)="navigationClick.emit(subItem)"
                        mobiTealiumTrackLinkOnsite="{{ subItem.title }}"
                        [trackingLinkTarget]="subItem.urlLink"
                        trackingLinkAction="click"
                        trackingLinkCategory="OnSiteLink - Content Navigation"
                        class="mobi-ui-padding-y-xs mobi-ui-padding-x-none"
                    >
                        <span class="mobi-ui-font-realist mobi-ui-font-size-100 xs:mobi-ui-font-size-150">
                            <div class="order-number-title-container">
                                @if (subItem.orderNumber) {
                                    <span class="order-number mobi-ui-margin-r-xxxs">{{ subItem.orderNumber }}</span>
                                }
                                <span class="title">{{ subItem.title }}</span>
                            </div>
                        </span>
                        <mobi-ui-icon-major name="go_to_link" />
                    </li>
                }
            }
        </ul>
    </div>
}
