import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';

import { JsonPipe, NgFor, NgIf } from '@angular/common';

import {
    MobiUiIconComponent,
    MobiUiIconsRegistry,
    MobiUiSidenavComponent,
    MobiUiSidenavItemLevel1Component,
    MobiUiSidenavItemLevel2Component,
    MobiUiSidenavItemLevel3Component,
} from '@mobi/rwc-ui-components-ng-jslib';
import { mobiIconGoToLink, mobiIconRight } from '@mobi/rwc-utils-icons-jslib';

import { TealiumTrackLinkOnsiteDirective } from '@mobi/tea-tagmanager-ng-jslib';

import { NavigationItem } from '../../model/atomic-model';

@Component({
    standalone: true,
    selector: 'oiv-viewer-navigation-index',
    templateUrl: './navigation-index.component.html',
    styleUrls: ['./navigation-index.component.scss'],
    imports: [
        MobiUiIconComponent,
        NgFor,
        NgIf,
        MobiUiSidenavComponent,
        MobiUiSidenavItemLevel1Component,
        MobiUiSidenavItemLevel2Component,
        MobiUiSidenavItemLevel3Component,
        JsonPipe,
        TealiumTrackLinkOnsiteDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationIndexComponent {
    private mobiUiIconsRegistry = inject(MobiUiIconsRegistry);

    constructor() {
        this.mobiUiIconsRegistry.registerIcons([mobiIconRight, mobiIconGoToLink]);
    }

    @Input() navigationItems: NavigationItem[] = [];

    @Output() navigationClick = new EventEmitter<NavigationItem>();
}
